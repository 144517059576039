import { gql } from '@apollo/client';

export const GET_JOB_OFFER_FOR_JOBS = gql`
  query Query($slug: String!) {
    getJobOfferForJobs(slug: $slug) {
      jobOffer {
        id
        type
        title
        typeLabel
        about
        introduction
        bonuses
        prerequisites
        legalInfos
        location {
          city
          postalCode
          coordinates
        }
        salary {
          minRate
          maxRate
          currencyCode
          unitRate
        }
        salaryFormattedCurrency
        salaryFormattedUnit
        requiredCertificates
        requiredCertificatesLabel
        requiredCertificatesLabels
        requiredExperienceLabel
        aboutHTML
        introductionHTML
        bonusesHTML
        prerequisitesHTML
        legalInfosHTML
        monthsOfExperience
        weeklyHours
      }
      jobPost {
        slug
        refId
        publishedAt
        discardedAt
        published
      }
    }
  }
`;

export const GET_JOB_OFFER_STATIC_MAP = gql`
  query Query($jobOfferId: String!) {
    getJobOfferStaticMapForJobs(jobOfferId: $jobOfferId)
  }
`;

export const GET_INFO_FOR_JOBS_SITEMAP = gql`
  query GetInfoForJobsSitemap {
    getInfoForJobsSitemap {
      slug
      updatedAt
    }
  }
`;
